import React from "react";
import './styles.css';




<link
      href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap"
      rel="stylesheet"
    />


function Footer(){

    return (
        <footer className="footer">
            <div className="text">
                © Website created by ProcsanArt
                <br />
                
            </div>
            {/* <div className="socialmedia">

            </div> */}
        </footer>
    )
}

export default Footer;