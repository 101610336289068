import img1 from "../../assets/galleryitems/img-1.avif";
import img2 from "../../assets/galleryitems/img-2.avif";
import img3 from "../../assets/galleryitems/img-3.avif";
import img4 from "../../assets/galleryitems/img-4.avif";
import img5 from "../../assets/galleryitems/img-5.avif";
import img6 from "../../assets/galleryitems/img-6.avif";
import img7 from "../../assets/galleryitems/img-7.avif";
import img8 from "../../assets/galleryitems/img-8.avif";
import img9 from "../../assets/galleryitems/img-9.avif";
import img10 from "../../assets/galleryitems/img-10.avif";
import img11 from "../../assets/galleryitems/img-11.avif";
import img12 from "../../assets/galleryitems/img-12.avif";
import img13 from "../../assets/galleryitems/img-13.avif";
import img14 from "../../assets/galleryitems/img-14.avif";
import img15 from "../../assets/galleryitems/img-15.avif";
import img16 from "../../assets/galleryitems/img-16.avif";
import img17 from "../../assets/galleryitems/img-17.avif";
import img18 from "../../assets/galleryitems/img-18.avif";
import img19 from "../../assets/galleryitems/img-19.avif";
import img20 from "../../assets/galleryitems/img-20.avif";
import img21 from "../../assets/galleryitems/img-21.avif";
import img22 from "../../assets/galleryitems/img-22.avif";
import img23 from "../../assets/galleryitems/img-23.avif";
import img24 from "../../assets/galleryitems/img-24.avif";
import img25 from "../../assets/galleryitems/img-25.avif";




const ImageData=[
    img1, img2, img3, 
    img4, img5, img6,
    img7, img8, img9,
    img10, img11, img12,
    img13, img14, img15,
    img16, img17, img18,
    img19, img20, img21,
    img22, img23, img24,
    img25
]
export default ImageData;