import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header/index";
import "./styles.css";
import { SocialIcon } from "react-social-icons";



function Home(){
    const [scrollPosition, setScrollPosition] = useState(0);
    const videoSources = [
        require("../../assets/faraway.mp4"),
        require("../../assets/commissionVal.mp4"),
    ];

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    });

    const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
    };

    const parallaxStyle = {
        transform: `translateY(${scrollPosition * 0.5}px)`,
    };
    // const parallaxStyle2 = {
    //     transform: `translateY(${(scrollPosition - window.innerHeight) * 0.5}px)`,
    // };
    const randomVideoIndex = Math.floor(Math.random() * videoSources.length);
    const randomVideoSource = videoSources[randomVideoIndex];

    return (
        <>
            <Header />
            <div id="body">
                <section id="banner" className="banner" style={parallaxStyle}>
                    <h1 id="parallaxbanner">ProcsanArt</h1>
                    <video poster="/path/to/thumbnail.jpg" autoPlay loop muted>

                        <source src={randomVideoSource} type="video/mp4" />
                    </video>
                </section>
                
                <div className="frontbox-content">
                    <div className="textfield">
                        Welcome to my Homepage, 
                        <br />
                        <br />
                        I'm Procsan, a digital artist with over a decade of experience. 
                        Through the use of vibrant colors, with an eye for detail and a unique style,
                        I bring my ideas to life on the digital canvas.
                        From captivating Fanarts to looping 2D animations and more!
                        <br />                        
                        <br />
                            Follow me on Twitter and Instagram @procsanart!
                        <br />
                        <br />
                        <div className="socialmedias">
                            <div className="twitter">
                                <SocialIcon url="https://twitter.com/ProcsanArt" bgColor="#fff" />
                            </div>
                            <div className="instagram">
                                <SocialIcon url="https://www.instagram.com/procsanart/" bgColor="#fff" />
                            </div>
                        </div>
                        <div>
                            <br />
                        </div>
                    </div>
                    <div className="box" >
                        <div className="picfield">
                            <img src={require("../../assets/images/1.avif")} type="avif" alt="profilepic"/>
                        </div>
                    </div> 
                </div>
{/*                 
                <section id="banner2" className="banner2" style={parallaxStyle2}>
                    <video autoPlay loop muted>
                        <source src={require("../../assets/commissionVal.mp4")} type="video/mp4" />
                    </video>
                </section>

                <div className="frontbox-content2">
                    <div className="contact-box">

                    </div>
                </div> */}
                
            </div>
            <Footer />
        </>
    )
}

export default Home;